import request from "@/utils/request";

// 商品列表
export function getGoodList(query) {
  return request({
    url: '/function/goods/skip/getList',
    headers: {
      isToken: false
    },
    method: 'get',
	params: query
  })
}
// 购物车列表
export function getCartList(userid) {
  return request({
    url: '/function/shoppingCart/getList/' + userid,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
// 加入购物车
export function addCartGood(query) {
  return request({
    url: '/function/shoppingCart/addInfo',
    headers: {
      isToken: true
    },
    method: 'post',
	data: query
  })
}
// 更新购物车
export function updateCartGood(query) {
  return request({
    url: '/function/shoppingCart/updateInfo',
    headers: {
      isToken: true
    },
    method: 'put',
	data: query
  })
}
// 删除购物车
export function delCartGood(ids) {
  return request({
    url: '/function/shoppingCart/deleteByIds/'+ids,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

