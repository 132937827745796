<template>
	<div class="carts maxBox">
		<div class="emptyBox" v-if="isEmpty">
			<h2>购物车为空</h2>
			<router-link class="btn" to="/products">继续购物</router-link>
			<div class="toLoginTips">
				<h4>您有帐户吗?</h4>
				<p>想要更快地付款 请先<router-link to="/account">登录</router-link>.</p>
			</div>
		</div>
		<div class="cartBox" v-else>
			<div class="titleBox">
				<h1>购物车</h1>
				<router-link to="/products">继续购物</router-link>
			</div>
			<div class="tableBox">
				<el-table :data="cartList" empty-text="暂无数据" style="width: 100%">
					<el-table-column prop="url" label="商品" >
						<template #default="props">
							<div class="infos">
								<el-image style="width: 100px; height: 100px" :src="props.row.img" fit="cover" />
								<div class="desInfos">
									<p class="title"><router-link to="/products/detail">{{props.row.title}}</router-link></p>
									<p class="price">¥{{props.row.unitPrice.toFixed(2)}}</p>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="title" label="数量" width="280" >
						<template #default="props">
							<el-input-number v-model="props.row.num" @input="(val)=>handleUpdate(val,props.row)" size="large" />
							<el-button style="margin-left: 1.5rem;" link :icon="Delete" @click="handleDelete(props.row)" />
						</template>
					</el-table-column>
					<el-table-column prop="price" label="总计" width="100" align="right" >
						<template #default="props">
							¥{{props.row.totalPrice.toFixed(2)}}
						</template>
					</el-table-column>
				</el-table>
				<div class="optionBox" v-if="totalMoney > 0">
					<p class="totalPrice">小计<span>¥{{totalMoney.toFixed(2)}} CNY</span></p>
					<p class="con" style="margin-bottom: 0;">收货人：<span>{{userInfo.nickName}}</span></p>
					<p class="con" style="margin-bottom: 0;margin-top: 0;">寄送至：<span>{{userInfo.email}}</span></p>
					<p class="con" style="margin-top: 0;">(电子券码形式商品发送至邮箱)</p>
					<router-link class="btn" to="/order-info">结算</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { Delete } from '@element-plus/icons-vue'
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { reactive, ref, onMounted } from 'vue'
	import useUserStore from '@/store/modules/user'
	import { getCartList,updateCartGood,delCartGood } from '@/api/index'
	import { useRouter } from 'vue-router'
	
	let router = useRouter();
	
	const isEmpty = ref(false)
	
	const cartList = ref([])
	const totalMoney = ref(0)
	const userID = ref(null)
	const userInfo = ref({})
	onMounted(()=>{
		userID.value = useUserStore().userid
		userInfo.value = useUserStore().userInfo
		if(userID.value){
			isEmpty.value = false
			init()
		}else{
			isEmpty.value = true
		}
	})
	const init = () => {
		getCartList(userID.value).then((res) => {
			cartList.value = res.rows
			totalMoney.value = 0
			res.rows.forEach((item) => {
				totalMoney.value += item.totalPrice
			})
		}).catch(() => {
			useUserStore().logOut().then(() => {
				router.push('/account/login')
			})
		})
	}
	const handleUpdate = (val,row) => {
		if(val < 1){
			handleDelete(row)
			return false
		}
		const { id } = row
		updateCartGood({
			id: id,
			num: val,
			userId: useUserStore().userid
		}).then((res) => {
			ElMessage({
			    message: 'Success',
			    type: 'success',
			})
			init()
		}).catch(() => {
			init()
		})
	}
	const handleDelete = (row) => {
		delCartGood(row.id).then((res) => {
			ElMessage({
			    message: 'Success',
			    type: 'success',
			})
			init()
		})
	}
	
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.carts{
		&.maxBox{flex-wrap: wrap;max-width: 118rem;}
		.btn{
			font-size: 1.5rem;letter-spacing:0.1rem;height: 4.5rem;
			display: inline-flex;align-items: center;
			padding: 0 3rem;background: #000;color: #fff;border: 1px solid #fff;
			transition: border 0.1s ease;position: relative;
			&:hover{
				border: 1px solid #000;
			}
		}
	}
	h2{width: 100%;font-size: 4rem;font-weight: 400;margin-bottom: 3rem;}
	.emptyBox{
		max-width: 446px;margin: 60px auto 0;width: 100%;
		text-align: center;
		
		.toLoginTips{
			h4{
				margin: 5.5rem 0 0.5rem;
				font-size: 2.4rem;font-weight: 400;
			}
			p{
				margin-top: 0.8rem;font-size: 1.6rem;
				color: rgba(18,18,18,0.75);
				a{
					text-decoration: underline;
					text-underline-offset: 0.3rem;
					&:hover{
						color: rgb(18,18,18);
					}
				}
			}
		}
	}
	.cartBox{
		width: 100%;
		.titleBox{
			width: 100%;margin: 5rem 0 3rem;
			display: flex;align-items: center;justify-content: space-between;
			h1{
				font-size: 4rem;font-weight: 400;
				margin-bottom: 0;
			}
			a{
				font-size: 1.6rem;text-decoration: underline;
				text-underline-offset: 0.3rem;
				&:hover{
					color: rgb(18,18,18);
				}
			}
		}
		.tableBox{
			display: flex;flex-wrap: wrap;
			.el-table{
				width: 100%;
			}
			.infos{
				display: flex;align-items: center;
				.desInfos{
					margin-left: 4rem;
					p.title{
						font-size: 1.5rem;
					}
					p.price{
						font-size: 1.4rem;margin-top: 0.6rem;
						color: rgba(18,18,18,0.75);
					}
				}
			}
			.optionBox{
				justify-self: flex-end;width: 100%;text-align: right;
				margin-top: 4rem;
				.totalPrice{
					font-size: 1.6rem;
					span{
						margin-left: 2rem;letter-spacing: 0.06rem;
					}
				}
				.con{
					margin: 2.2rem 0 1.6rem auto;
					font-size: 1.3rem;color: rgba(18,18,18,0.75);
				}
				.btn{
					width: 30rem;justify-content: center;margin-top: 1rem;
				}
			}
		}
	}
	
</style>
